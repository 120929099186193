<template>
    <!--full width banner-->
    <div class="row justify-content-center">
        <div>
            <img src="../assets/main_bg.png" :style="isMobile()? 'margin-top:60px; width:100%':'width:100%;'">
        </div>

        <!-- what we do -->
        <div class="divide60" id="Company"></div>
        <div class="col-sm-10 col-md-9 col-lg-9 col-xl-6">
            <div class="center-heading">
                <h2>What we do</h2>
                <span class="center-line"></span>
                <p class="sub-text margin40 p-3">
                    (주)디렉션소프트는 iOS, Android 기반의 앱, Framework(library) 개발 업무를 수행합니다. 9년간의 개발 경험과 최근 6년간 쇼핑몰 앱을 전문적으로 개발함으로서 쇼핑몰앱에 필요한 다양한 요소에 대한 개발 구축 경험을 가지고 있어 항상 안정적이고 오픈 기간에 충실한 앱 개발을 보장합니다.
                </p>
            </div>
        </div>

        <!-- 카드 -->
        <div class="divide30"></div>
        <div class="col-sm-12 col-md-10 col-lg-10 col-xl-6">
            <div class="row justify-content-center">
                <b-card-group columns v-for="(item,index) in card" :key="index" style="max-width:20rem; margin-right:2px margin-left:2px" class="col-sm-12 col-md-9 col-lg-9 p-0">
                    <b-card
                        style="max-width: 25rem; min-height:21rem"
                        class="m-1"
                    >
                    <img :src="item.png" class="margin10" style="width:16px hegiht:16px"/>
                    <b-card-title>{{item.title}}</b-card-title>
                    <span class="center-line"></span>
                        <b-card-text class="pt-1" v-for="(itemContent,index) in item.content" :key="index">
                             {{itemContent}}
                       </b-card-text>
                    </b-card>
                </b-card-group>
            </div>
        </div>

        <!-- History -->
        <div class="divide60" id="History"></div>
        <div class="col-sm-12 col-md-10 col-lg-8">
            <div class="center-heading">
                <h2>History</h2>
                <span class="center-line"></span>
            </div>  
            <div class="row p-3" v-for="(item,index) in history" :key="index">
                <div class="col-md-2">
                    <h4 class="tithistory"><font color="ef402a">{{item.year}}</font></h4>
                </div>
                <div class="col-md-10" >
                    <ul class="list-unstyled cat-list">
                        <li v-for="(subItem, index) in item.subItem" :key="index">
                            <span class="si-text">{{subItem.month}}</span>{{subItem.content}}</li>
                    </ul>
                    <!-- <div class="divide30"></div> -->
                </div>
            </div>
        </div>

        <!-- RecentWorks -->
        <div class="divide60" id="RecentWorks"></div>
        <div class="col-sm-8">
            <div class="center-heading">
                <h2>Recent Works</h2>
                <span class="center-line"></span>
            </div>  
            <div class="row justify-content-center">
                <div v-for="(item,index) in portfolio" :key="index"  :class="isMobile()? 'col-11':'col-4'">
                    <div class="project-post">
                        <div class="item-img-wrap">
                            <img :src="item.png" class="img-responsive" alt="working">
                        </div>
                        <div class="work-desc">
                            <h4><a href="javascript:void(0);">{{item.customer}}</a></h4>
                            <span>{{item.subject}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Recruit -->
        <div class="divide60" id="Recurit"></div>
        <div class="col-sm-12 col-md-10 col-lg-8">
            <div class="center-heading">
                <h2>Recruit</h2>
                <span class="center-line"></span>
            </div>  
            <div class="row justify-content-center">
                <div class="blog-post p-4">
                    <div class="blog-postbox">
                        <div class="text-center">
                            <img src="../assets/recruit.jpg" class="center-block img-responsive" alt="workimg" :style="isMobile()? 'width:100%':'width:80%;'">
                        </div>
                        <div class="results-box" style="text-align: left;">
                            <h4><font color="ef402a">모집분야</font></h4>
                            <p>-&nbsp;&nbsp; 웹/모바일 개발자(JAVA)<br>
                            -&nbsp;&nbsp; iOS/Android 개발자(Objective-C, Swift, Android Java, Kotlin)
                            </p>

                            <h4><font color="ef402a">지원형태</font></h4>
                            <p>-&nbsp;&nbsp; 전문대 졸 이상(관련 학과/자격증 우대)<br>
                            </p>
                            <h4><font color="ef402a">모집기간</font></h4>
                            <p>-&nbsp;&nbsp; 상시채용<br>

                            </p>
                            <h4><font color="ef402a">지원방법</font></h4>
                            <p>-&nbsp;&nbsp; 지원을 희망하시는 분은 이력사항을 작성하여 아래 이메일로 전달해 주시면 서류전형 합격여부를 가급적 빠른 시간 내로 통보해 드립니다.
                            </p>
                        </div>
                        <div class="divide10"></div>
                        <div class="about-recruit" >
                            <img src="../assets/mailtojob.png" class="mb-1 popular-recruit2" alt="">
                            이메일 보내는 곳 : <a href="mailto:hans@directionsoft.com"> hans@directionsoft.com</a>
                        </div>
                    </div> 
                </div>  
            </div>
        </div>
        <!-- location -->
        <div class="divide20"></div>
        <div class="col-sm-8">
            <div class="center-heading" id="Reference">
                <h2>Location</h2>
                <span class="center-line"></span>
            </div>  
        </div>
        <div class="row">
            <div class="divide20" id="ContactUs"></div>

            <div clsss="location">
                <iframe class="contactUs" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7070.717049588593!2d126.88570633678387!3d37.525965501916694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9e92ba9c8b2b%3A0xa13424f63fc38481!2z7ISc7Jq47Yq567OE7IucIOyYgeuTse2PrOq1rCDslpHsgrDroZwgNTM!5e0!3m2!1sko!2skr!4v1679882075030!5m2!1sko!2skr" allowfullscreen></iframe>
                <!-- <iframe class="contactUs" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.516509073826!2d126.89583890059481!3d37.519320023858526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9ef055ffe9ef%3A0xbb2fd682c189b09e!2zKOyjvCnrlJTroInshZjshoztlITtirg!5e0!3m2!1sko!2sus!4v1562293887632!5m2!1sko!2sus" allowfullscreen></iframe> -->
            </div>
            <div class="divide20"></div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            history:[{
                year:'2021',
                subItem:[
                    {month:'11',content:'파이언넷과 신세계인터네셔날 S.I.VILLAGE 앱 개발 계약'},
                    {month:'07',content:'데브올과 Vinyl runable (달리기 앱) 앱 계약'},
                    {month:'01',content:'필라넷과 삼성전자복합인증 앱 운영 계약'},
                    {month:'01',content:'갤러리아몰 앱 운영 계약 2년차'},
                    {month:'01',content:'롯데홈쇼핑 앱 운영 게약'},
                    {month:'01',content:'롯데인터넷면세졈 운영 계약 3년차'},
                    ]
                },
                {
                year: '2020',
                subItem:[
                    {month:'10', content:'SecuWorks와 IOT 모니터링 관제 시스템 개발 계약'},
                    {month:'08', content:'신세계면세점 앱 개발 계약'},
                    {month:'01', content:'갤러리아몰 앱 운영 계약'},
                    {month:'01', content:'롯데인터넷면세점 운영 계약 2년차'},
                    ]
                },
                {
                year: '2019',
                subItem:[
                    {month:'12', content:'네오랩컨버전스와 네오스튜디오 iOS용 앱 개발 계약'},
                    {month:'12', content:'필라넷과 mOTP앱 개발 계약'},
                    {month:'08', content:'롯데인터넷면세점 트립톡 2차 개발 계약'},
                    {month:'08', content:'갤러리아몰 리뉴얼 앱 개발 프로젝트 계약'},
                    {month:'07', content:'롯데쇼핑 검색/탐색 개선 프로젝트 계약'},
                    {month:'07', content:'롯데인터넷면세점 상품상세 개선 프로젝트 계약'},
                    {month:'05', content:'롯데인터넷면세점 트립톡 1차 개발 계약'},
                    {month:'01', content:'롯데쇼핑 앱 운영 계약'},
                    {month:'01', content:'롯데인터넷면세점 앱 운영 계약'},
                    {month:'01', content:'갤러리아몰 앱 운영 계약'},
                    ]
                },
                {
                year: '2018',
                subItem:[
                    {month:'10', content:'롯데쇼핑 검색/탐색 개선 프로젝트 계약'},
                    {month:'08', content:'롯데쇼핑 투게더SDK 개발 및 적용 계약'},
                    {month:'07', content:'S.I.VILLAGE, JAJU 앱 리뉴얼 계약'},
                    {month:'06', content:'롯데인터넷면세점 앱 리뉼얼 계약'},
                    {month:'05', content:'롯데쇼핑 검색 고도화 프로젝트 계약'},
                    {month:'04', content:'LG Puricare 미니 공청기 연동 앱 개발 계약'},
                    {month:'03', content:'롯데쇼핑 보이스커머스 기능 개선 계약'},
                    {month:'01', content:'갤러리아몰 앱 운영 계약'},
                    {month:'01', content:'롯데쇼핑 앱 운영 계약'},
                    ]
                },
                {
                year: '2017',
                subItem:[
                    {month:'11', content:'AK몰 앱 개선 계약'},
                    {month:'10', content:'롯데닷컴 폴더 앱 개발 계약'},
                    {month:'04', content:'롯데닷컴 앱 리뉴얼 계약'},
                    ]
                },
                {
                year: '2016',
                subItem:[
                    {month:'10', content:'LG BizSkype 연동 앱 개발 계약'},
                    {month:'03', content:'FineVu 블랙박스 연동 앱 개발 계약'},
                    {month:'03', content:'11번가 글로벌 플랫폼 앱 개발 계약'}
                    ]
                }
            ],
            portfolio:[
                {png:require('../assets/apps/11st_thai.png'), customer:'11번가', subject:'11번가 태국 Native 앱 개발'},
                {png:require('../assets/apps/11st_mal.png'),customer:'11번가',subject:'11번가 말레이시아 Native 앱 개발'},
                {png:require('../assets/apps/kshop.png'),customer:'KTH K쇼핑',subject:'K쇼핑 하이브리드 & Native 앱 개발'},
                {png:require('../assets/apps/galleria.png'),customer:'한화 갤러리아몰',subject:'갤러리아몰 하이브리드 앱 개발'},
                {png:require('../assets/apps/elandmall.png'),customer:'이랜드리테일',subject:'이랜드몰 Native앱 개발 및 운영'},
                {png:require('../assets/apps/galleriadfs.png'),customer:'한화갤러리아 타임월드',subject:'갤러리아면세점 국문/중문 Hybrid 앱 개발'},
                {png:require('../assets/apps/lottecom.png'),customer:'롯데쇼핑',subject:'롯데닷컴 리뉴얼, 폴더앱, 투게더SDK 개발 및 운영'},
                {png:require('../assets/apps/lottedfs.png'),customer:'롯데인터넷면세점',subject:'롯데인터넷면세점 앱 리뉴얼 및 운영'},
                {png:require('../assets/apps/lottesuper.png'),customer:'롯데쇼핑',subject:'롯데닷컴 리뉴얼, 폴더앱, 투게더SDK 개발 및 운영'},
            ],
            card:[
                {png:require('../assets/index_apps.png'),title:'앱 개발 업무',content:['20여년 개발경험을 보유한 다수의 시니어 개발자를 기반으로 2010년 창업부터 지금까지 모바일 앱 개발을 해온 앱 개발 전문업체입니다.'],},
                {png:require('../assets/index_mall.png'),title:'쇼핑몰 앱 개발',content:['11번가(태국, 말레이시아), 이랜드몰, 갤러리아몰, 갤러리아면세점, 롯데쇼핑(롯데닷컴, 롯데슈퍼, 롯데인터넷면세점), AK몰, S.I.VILLAGE, 웰스토리몰, 동원몰 등의 앱 개발/운영 경험을 보유하고 있습니다.']},
                {png:require('../assets/human-resources.png'),title:'HR 서비스',content:['* 고급인재 채용서비스와 그 외의 다양한 인사관련 서비스를 고객의 품격에 맞게 제공합니다.','* 고객의 다양한 요구사항에 맞는 맞춤식 인사관련 전문 서비스를 제공합니다.','* 다양한 분야의 경험 많은 전문가 들로 구성']},
                // {png:require('../assets/index_netapp.png'),title:'NetApp 파트너',content:'넷앱은 스토리지시스템과 클라우드, 가상화, 빅데이터, 데이터보호 솔루션을 제공하는 글로벌 업체로 스토리지 시스템 구축을 통해서 IT 효율성 및 비즈니스 민첩성이 향상되고, 높은 애플리케이션 가동 시간을 제공하며, 데이터를 간편하게 관리할 수 있습니다.'},
                // {png:require('../assets/index_oracle.png'),title:'Oracle 파트너',content:'Oracle은 모든 규모의 회사를 위한 광범위한 솔루션 포트폴리오를 갖춘 비즈니스 소프트웨어 공급 1위 업체입니다'},
                // {png:require('../assets/index_dell.png'),title:'Dell 파트너',content:''},
                // {png:require('../assets/appier.jpeg'),title:'Appier 파트너',content:'AI 기반 마케팅 자동화 플랫폼'},
                // {png:require('../assets/splunk.png'),title:'Splunk 파트너',content:'데이터를 행동으로 바꾸는 글로벌 머신데이터 플랫폼, 스플렁크'},
            ]
        }
    },
    methods:{
        isMobile() {
            var UserAgent = navigator.userAgent;

            if (UserAgent.match(/iPhone|iPod|iPad|Android|iOS|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null)
            {
                return true;
            }else{
                return false;
            }
        },
        txtReplace(text) {
            var repaceText = text.replace('\n','');
            return repaceText;
        }
    },
    mounted(){
    }
}
</script>

<style>
    html,body{
        overflow-x: hidden;
        margin:0;
    }

    .center-heading {
        text-align: center;
        margin-bottom: 20px;
    }

    .center-heading h2 {
        margin-bottom: 0;
        font-weight: 700;

        letter-spacing: -1px;
        color: #000;
        font-size: 30px;
    }
    .center-line {
        display: inline-block;
        width: 70px;
        height: 1px;
        border-top: 1px solid #bbb;
        /* border-bottom: 1px solid $skincolor; */
        margin: auto;
    }
</style>